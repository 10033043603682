import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { debounce } from 'throttle-debounce';

import backendApi from '../apis/backend';

import FormControl from '@mui/material/FormControl';
import { isRuneOrAddress, isRune, sanitizeRune, isTaprootAddress } from './Utils';

export default function WalletInput(props: any) {
    const navigate = useNavigate();

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<[]>([]);
    const [loading, setLoading] = React.useState(false);

    const fetch = React.useMemo(
        () =>
            debounce(700, (input, callback) => {
                backendApi
                    .get('rune/get_runes_autocomplete', {
                        params: {
                            query: sanitizeRune(input),
                        },
                    })
                    .then(callback);
            }),
        []
    );

    React.useEffect(() => {
        if (inputValue === '' || inputValue.length < 3) {
            return;
        }

        setLoading(true);

        fetch(inputValue, (response: any) => {
            setOptions(response.data);
            setLoading(false);
        });
    }, [inputValue]);

    return (
        <Stack
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: 2,
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', md: '100%' },
                }}
            >
                {/* TODO: validate address */}
                <form
                    onSubmit={(e: any) => {
                        e.preventDefault();
                        if (e.target instanceof HTMLFormElement) {
                            const address = e.target[0]?.value;
                            if (isRune(sanitizeRune(address))) {
                                // TODO: autocomplete runes
                                // TODO: validate if rune or address doesn't exist
                                return navigate(`/${sanitizeRune(address)}`, { replace: true });
                            } else if (isTaprootAddress(address)) {
                                return navigate(`/${address}`);
                            } else {
                                // TODO: show error message
                            }
                        }
                    }}
                >
                    <FormControl
                        sx={{
                            width: { xs: '100%', md: '100%' },
                            alignItems: 'flex-end',
                        }}
                        variant='outlined'
                    >
                        <Autocomplete
                            sx={{ width: '100%' }}
                            loading={loading}
                            options={[...options, ...(isTaprootAddress(inputValue) ? [{ wallet: inputValue }] : [])]}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            onChange={(event, newValue: any) => {
                                if (newValue && newValue?.spaced_rune && isRune(sanitizeRune(newValue?.spaced_rune))) {
                                    return navigate(`/${sanitizeRune(newValue.spaced_rune)}`, { replace: true });
                                } else if (isTaprootAddress(newValue?.wallet)) {
                                    return navigate(`/${newValue?.wallet}`, { replace: true });
                                } else {
                                    // TODO: show error message
                                }
                            }}
                            filterOptions={(x) => x}
                            filterSelectedOptions
                            getOptionLabel={(option: any) =>
                                typeof option === 'string' ? option : option.spaced_rune || option.wallet
                            }
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        error={
                                            inputValue !== '' &&
                                            ((options?.length === 0 && loading === false) ||
                                                !isRuneOrAddress(inputValue))
                                        }
                                        size='small'
                                        fullWidth
                                        placeholder='Search for rune or wallet address'
                                        id='fullWidth'
                                        sx={{ width: '100%' }}
                                    />
                                );
                            }}
                            renderOption={(props, option: any) => {
                                const { key, ...optionProps } = props;
                                const matches = match(option?.spaced_rune || option?.wallet, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option?.spaced_rune || option?.wallet, matches);
                                return (
                                    <li key={key} {...optionProps}>
                                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                            <Typography variant='body1'>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 1000 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}{' '}
                                                {option?.symbol}
                                            </Typography>
                                            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                                <strong>#{option?.number || 'Address'}</strong>
                                            </Typography>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                        {/* <br />
                        <Button variant='contained' size='small' color='primary' type='submit'>
                            Search
                        </Button> */}
                    </FormControl>
                </form>
            </Box>
        </Stack>
    );
}
