import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import bigDecimal from 'js-big-decimal';

import moment from 'moment';

import { Link } from 'react-router-dom';

const formatAddress = (address: string) => {
    // TODO: add link to wallet explorer page
    return (
        <Link to={`/${address}`} style={{ textDecoration: 'none' }}>
            {address.slice(0, 4)}...{address.slice(-4)}
        </Link>
    );
};

const getListItemByKind = (runeData: any, activity: any) => {
    const timestamp = (
        <Typography variant='body1' component='span'>
            {moment(activity.createdAt).fromNow()} -{' '}
        </Typography>
    );
    const amountUsd = new bigDecimal(activity.amount)
        .multiply(new bigDecimal(runeData.prices.btc))
        .multiply(new bigDecimal(runeData.meMarketInfo.floorUnitPrice.value))
        .divide(new bigDecimal(10 ** 8));
    const amount = (
        <>
            {' '}
            <Typography variant='body1' component='span'>
                <strong>
                    {activity.amount} {runeData.symbol}
                </strong>
            </Typography>
            <Typography variant='caption' component='span'>
                {' '}
                ${amountUsd.round(2).getPrettyValue()} USD
            </Typography>
        </>
    );
    switch (activity.kind) {
        case 'create_sell_order':
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span' sx={{ color: 'lightcoral' }}>
                                    SELL ORDER CREATED
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {' '}
                                    by{' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.address)}</strong>{' '}
                                </Typography>
                                {amount}
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
        case 'market_sell_broadcasted':
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span' sx={{ color: 'lightcoral' }}>
                                    SELL BROADCASTED
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.oldOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span' sx={{ fontSize: 'small' }}>
                                    {' '}
                                    <ArrowForwardIcon fontSize='small' style={{ margin: '0 0 -5px 0' }} />{' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.newOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span'></Typography>
                                {amount}
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
        case 'order_cancelled':
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span' sx={{ color: 'lightyellow' }}>
                                    ORDER CANCELLED
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {' '}
                                    by{' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.address)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span'></Typography>
                                {amount}
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
        case 'bid_accepted':
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span' sx={{ color: 'lightgreen' }}>
                                    BID ACCEPTED
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.oldOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span' sx={{ fontSize: 'small' }}>
                                    {' '}
                                    <ArrowForwardIcon fontSize='small' style={{ margin: '0 0 -5px 0' }} />{' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.newOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {amount}
                                </Typography>
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
        case 'buying_broadcasted':
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span' sx={{ color: 'lightgreen' }}>
                                    BUYING BROADCASTED
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    {' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.oldOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span' sx={{ fontSize: 'small' }}>
                                    {' '}
                                    <ArrowForwardIcon fontSize='small' style={{ margin: '0 0 -5px 0' }} />{' '}
                                </Typography>
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.newOwner)}</strong>
                                </Typography>
                                <Typography variant='body1' component='span'></Typography>
                                {amount}
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
        default:
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <div>
                                {timestamp}
                                <Typography variant='body1' component='span'>
                                    <strong>{formatAddress(activity.address)}</strong>{' '}
                                </Typography>
                                <Typography variant='body1' component='span'></Typography>
                                {amount}
                            </div>
                        }
                        secondary={''}
                    />
                </ListItem>
            );
    }
};

export default function LastActivities(props: any) {
    const activitiesToShow = props?.runeData?.meActivities?.slice(0, 10) || [];
    return (
        <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2'>
                    Last market activities
                </Typography>
                <List>
                    {activitiesToShow.map((activity: any, index: any) => (
                        <React.Fragment key={index}>
                            {getListItemByKind(props.runeData, activity)}
                            {index < activitiesToShow.length - 1 && <Divider component='li' />}
                        </React.Fragment>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}
