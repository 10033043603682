import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Alert from '@mui/material/Alert';

import moment from 'moment';

import { Link } from 'react-router-dom';
import millify from 'millify';

const formatOperations = (address: any, walletData: any, transaction: any) => {
    if (transaction?.interpretation == null) return null;
    return transaction.interpretation.operations.map((operation: any) => (
        <ListItem style={{ padding: '3px 10px' }}>
            <ListItemText>
                <Typography variant='body1' component='span'>
                    {(() => {
                        if (operation.type === 'TRANSFER' && operation.to === address)
                            return (
                                <Typography variant='body1' component='span' sx={{ color: 'lightgreen' }}>
                                    RECEIVED
                                </Typography>
                            );
                        else if (operation.type === 'TRANSFER' && operation.from === address)
                            return (
                                <Typography variant='body1' component='span' sx={{ color: 'lightcoral' }}>
                                    SENT
                                </Typography>
                            );
                        else return operation.type;
                    })()}{' '}
                    {operation.amount > 0 ? millify(operation.amount) + ' ' : ''}
                    of{' '}
                    <Link
                        to={`/${transaction.txid}`}
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'none' }}
                        color='secondary'
                    >
                        {operation.rune.entry.spaced_rune} {operation.rune.entry.symbol}
                    </Link>
                </Typography>
            </ListItemText>
        </ListItem>
    ));
};

export default function WalletActivities(props: any) {
    const transactionsToShow = props?.walletData?.wallet?.transactions?.reverse() || [];
    return (
        <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2'>
                    Last runes transactions
                </Typography>
                <List>
                    {transactionsToShow.map((transaction: any, index: any) => (
                        <React.Fragment key={index}>
                            <ListItem style={{}}>
                                <ListItemText>
                                    <Typography variant='body1' component='span'>
                                        {moment.unix(transaction.status.block_time).fromNow()} -{' '}
                                    </Typography>
                                    <Link
                                        to={`https://mempool.space/tx/${transaction.txid}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{ color: '#ff7500', textDecoration: 'none' }}
                                    >
                                        {transaction.txid.slice(0, 6)}...{transaction.txid.slice(-6)}
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            {transaction?.interpretation &&
                                formatOperations(props.address, props.walletData, transaction)}

                            {index < transactionsToShow.length - 1 && <Divider component='li' />}
                        </React.Fragment>
                    ))}
                </List>
                <Alert severity='info'>This section is in construction</Alert>
            </CardContent>
        </Card>
    );
}
