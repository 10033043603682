import moment from 'moment';

import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { columns, rows } from '../internals/data/gridData';

import { Link } from 'react-router-dom';

import { SparkLineChart } from '@mui/x-charts';

const renderSparklineCell = (rune: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
            <SparkLineChart
                data={rune.meData.mePriceSeries?.map((price: any) => price.cFP * 10 ** rune.divisibility) || []}
                width={100}
                height={32}
                plotType='line'
                showHighlight
                showTooltip
                colors={['hsl(210, 98%, 42%)']}
                xAxis={{
                    scaleType: 'band',
                    data:
                        rune?.meData.mePriceSeries?.map((price: any) => moment.utc(price.ts).format('MMM DD, HH:mm')) ||
                        [],
                }}
            />
        </div>
    );
};

export default function WalletRunesDataGrid(props: any) {
    // TODO: add runes avatar
    return (
        <DataGrid
            autoHeight
            rows={props.walletData.wallet.runes_balances.map((rune: any) => {
                return {
                    ...rune,
                    id: rune.spaced_rune,
                };
            })}
            columns={[
                {
                    field: 'spaced_rune',
                    headerName: 'Rune name',
                    flex: 1.5,
                    minWidth: 200,
                    sortable: true,
                    renderCell: (params) => {
                        return (
                            <Link to={`/${params.row.spaced_rune}`}>
                                {' '}
                                <strong>{params.value}</strong>{' '}
                            </Link>
                        );
                    },
                },
                {
                    field: 'amount',
                    headerName: 'Amount',
                    headerAlign: 'right',
                    align: 'right',
                    flex: 1,
                    minWidth: 80,
                    renderCell: (params) => {
                        return (
                            <strong>
                                {params.value} {params.row.symbol}
                            </strong>
                        );
                    },
                },
                {
                    field: 'assetsValueUsd',
                    headerName: 'Asset value',
                    headerAlign: 'right',
                    align: 'right',
                    flex: 1,
                    minWidth: 100,
                    sortable: true,
                    renderCell: (params) => {
                        return <strong>{params.value ? '$' + params.value.toFixed(2) : '-'}</strong>;
                    },
                },
                {
                    field: 'priceSeries',
                    headerName: 'Price (24h)',
                    headerAlign: 'center',
                    align: 'center',
                    flex: 1,
                    minWidth: 100,
                    renderCell: (params) => renderSparklineCell(params.row),
                },
            ]}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
                sorting: { sortModel: [{ field: 'assetsValueUsd', sort: 'desc' }] },
            }}
            pageSizeOptions={[10, 20, 50]}
            disableColumnResize
            density='compact'
            slotProps={{
                filterPanel: {
                    filterFormProps: {
                        logicOperatorInputProps: {
                            variant: 'outlined',
                            size: 'small',
                        },
                        columnInputProps: {
                            variant: 'outlined',
                            size: 'small',
                            sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                            variant: 'outlined',
                            size: 'small',
                            sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                            InputComponentProps: {
                                variant: 'outlined',
                                size: 'small',
                            },
                        },
                    },
                },
            }}
        />
    );
}
