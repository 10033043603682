import * as React from 'react';

import moment from 'moment';

import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import backendApi from '../apis/backend';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import RuneHolders from './RuneHolders';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

import OrdersBook from './OrdersBook';
import LastActivities from './LastActivities';

import millify from 'millify';

import { Link } from 'react-router-dom';

import XIcon from '@mui/icons-material/X';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { formatSmallNumber, sanitizeRune, formatPercentage } from './Utils';
import { format } from 'prettier';
import { RunePriceSeries } from './RunePriceSeries';

const blocksPerDay = 144;

// TODO: add team's note, that is manually added as a context

export default function RuneMainGrid(props: any) {
    const {
        isLoading,
        isError,
        data: runeData,
    } = useQuery({
        queryKey: ['users', props.address],
        queryFn: async () => {
            const response = await backendApi.get('rune/get_by_name', {
                params: {
                    spaced_rune: sanitizeRune(props.address),
                },
            });
            return response.data;
        },
    });

    if (isError || runeData === '') {
        return <div>Rune was not found</div>;
    }

    let supply = 0;
    let totalMinted = 0;
    let adjustedPremine = 0;

    let timePassedSinceEtch = 0;
    let formattedTimePassed = '';

    if (!isLoading) {
        supply = (runeData.termsAmount * runeData.termsCap + runeData.premine) / 10 ** runeData.divisibility;
        totalMinted = (runeData.mints * runeData.termsAmount) / 10 ** runeData.divisibility;
        adjustedPremine = runeData.premine / 10 ** runeData.divisibility;

        timePassedSinceEtch = (runeData.blockheight - runeData.block) * 10; // in minutes
        formattedTimePassed = moment.duration(timePassedSinceEtch, 'minutes').humanize();
    }

    const getTrend = (data: number[]) => {
        if (data.length < 2) {
            return 'up';
        }
        return data[data.length - 1] > data[0] ? 'up' : 'down';
    };

    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            {/* cards */}
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Alert severity='info' style={{ width: '100%' }}>
                    This application is in active development. Improvements and new features are being added. Bugs are
                    being fixed as they are discovered. Please report any issues you find.
                </Alert>
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <HighlightedCard
                        title='Enjoying the app? Refer us to your crypto goups!'
                        subtitle='Help us grow and improve our products'
                    />
                </Grid> */}
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <React.Fragment>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography variant='h5' component='div'>
                                        {runeData.spaced_rune} {runeData.symbol}
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Etched {formattedTimePassed} ago
                                    </Typography>
                                    <br></br>
                                    <Typography variant='body2' style={{ textAlign: 'right' }}>
                                        {millify(supply)} supply
                                    </Typography>
                                    <div style={{ padding: '10px 0' }}>
                                        <LinearProgress
                                            variant='buffer'
                                            value={(100 * totalMinted) / supply}
                                            valueBuffer={(100 * (totalMinted + adjustedPremine)) / supply}
                                        />
                                    </div>
                                    <Typography sx={{ color: 'text.secondary' }} style={{ textAlign: 'right' }}>
                                        {millify(adjustedPremine)} premine ({formatPercentage(adjustedPremine, supply)})
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }} style={{ textAlign: 'right' }}>
                                        {totalMinted} minted ({formatPercentage(totalMinted, supply)})
                                    </Typography>
                                </div>
                            )}
                        </CardContent>
                    </React.Fragment>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Details
                                    </Typography>
                                    <Stack
                                        direction='column'
                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                    >
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Typography variant='h6' component='p'>
                                                    {runeData.id}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Rune ID
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <br />
                                    <Stack
                                        direction='column'
                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                    >
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Typography variant='h6' component='p'>
                                                    <Link
                                                        to={`https://mempool.space/tx/${runeData.etching}`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        style={{ color: '#ff7500', textDecoration: 'none' }}
                                                    >
                                                        {runeData.etching.slice(0, 6)}...{runeData.etching.slice(-6)}
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Etching transaction
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                    ) : (
                        <StatCard
                            title='Market Cap'
                            value={
                                '$' +
                                (runeData.meMarketInfo?.marketCap
                                    ? millify(runeData.meMarketInfo.marketCap * 1000 * 100)
                                    : '0')
                            }
                            interval='Last 30d daily values'
                            data={
                                runeData?.snapshotsSeries.map(
                                    (snapshot: any) => snapshot.meListing.marketCap * 1000 * 100
                                ) || []
                            }
                            xArray={runeData?.snapshotsSeries.map((snapshot: any) => snapshot.timestampDate) || []}
                            trend={getTrend(
                                runeData?.snapshotsSeries.map((snapshot: any) => snapshot.meListing.marketCap) || []
                            )}
                            valueFormatter={(value: any) => '$' + millify(value)}
                            xAxisFormatter={(value: any) => moment.utc(value).format('MMM DD')}
                        />
                    )}
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                    ) : (
                        <StatCard
                            title='Volume (24h)'
                            value={'$' + millify(runeData.meMarketInfo.volume['1d'] / 1000)}
                            interval='Daily volume progression'
                            data={
                                runeData?.snapshotsSeries.map((snapshot: any) => snapshot.meListing.vol * 1000 * 100) ||
                                []
                            }
                            xArray={runeData?.snapshotsSeries.map((snapshot: any) => snapshot.timestampDate) || []}
                            trend={getTrend(
                                runeData?.snapshotsSeries.map((snapshot: any) => snapshot.meListing.vol) || []
                            )}
                            valueFormatter={(value: any) => '$' + millify(value)}
                            xAxisFormatter={(value: any) => moment.utc(value).format('MMM DD')}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {(() => {
                        if (isLoading) {
                            return (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            );
                        } else if (totalMinted + adjustedPremine < supply) {
                            return (
                                <Alert severity='warning' style={{ height: '100%' }}>
                                    The rune is still being minted.
                                    <br />
                                    <br />
                                    Before minting and buying check if it's not a copy of a previous rune.
                                </Alert>
                            );
                        } else if (adjustedPremine >= supply) {
                            return (
                                <Alert severity='warning' style={{ height: '100%' }}>
                                    The rune is 100% premine.
                                    <br />
                                    <br />
                                    Check the supply was fairly distributed by the creator before buying.
                                </Alert>
                            );
                        } else if (adjustedPremine / supply > 0.1) {
                            return (
                                <Alert severity='error' style={{ height: '100%' }}>
                                    A large chunk of the supply is premined.
                                    <br />
                                    <br />
                                    This usually indicates the a large portion of the supply is controlled by the
                                    creator. Only buy if you trust them.
                                </Alert>
                            );
                        } else {
                            return (
                                <Alert severity='success' style={{ height: '100%' }}>
                                    This rune is already minted out and has enought liquidity to be traded.
                                    <br />
                                    <br />
                                    Still, check if it's not a copy of a previous rune.
                                </Alert>
                            );
                        }
                    })()}
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Quick links
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://magiceden.io/runes/${sanitizeRune(props.address)}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Magic Eden
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://twitter.com/search?q=${encodeURIComponent(sanitizeRune(props.address))}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            endIcon={<XIcon />}
                                        >
                                            Twitter
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://luminex.io/rune/${encodeURIComponent(sanitizeRune(props.address))}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Luminex
                                        </Button>
                                    </Box>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                {totalMinted + adjustedPremine < supply && (
                    <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                        <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                            <CardContent>
                                {isLoading ? (
                                    // TODO: remove if rune was minted out and etched out after a few months ago
                                    <Skeleton
                                        variant='rectangular'
                                        width={210}
                                        height={120}
                                        style={{ width: '100%' }}
                                    />
                                ) : (
                                    <div>
                                        <Typography component='h2' variant='subtitle2' gutterBottom>
                                            Price per mint
                                        </Typography>
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{
                                                    alignContent: { xs: 'center', sm: 'flex-start' },
                                                    alignItems: 'flex-end',
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography variant='h4' component='p'>
                                                    {runeData.mintEstimateSats} sats
                                                </Typography>
                                                <Typography gutterBottom component='p' sx={{ color: 'text.secondary' }}>
                                                    ${formatSmallNumber(runeData.mintEstimateUsd)}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Price to mint a bag of {runeData.termsAmount} {runeData.symbol} in the
                                                current fee rate of {runeData.fees?.fastestFee} sats/vB
                                            </Typography>
                                        </Stack>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {totalMinted + adjustedPremine >= supply && (
                    <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                        {isLoading ? (
                            <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                        ) : (
                            <StatCard
                                title='Holders progression'
                                value={''}
                                interval='Last 30d daily values'
                                data={
                                    runeData?.snapshotsSeries.map((snapshot: any) => snapshot.meListing.holderCount) ||
                                    []
                                }
                                xArray={runeData?.snapshotsSeries.map((snapshot: any) => snapshot.timestampDate) || []}
                                trend={getTrend(
                                    runeData?.snapshotsSeries.map((snapshot: any) => snapshot.meListing.holderCount) ||
                                        []
                                )}
                                xAxisFormatter={(value: any) => moment.utc(value).format('MMM DD')}
                            />
                        )}
                    </Grid>
                )}
                {adjustedPremine < supply && (
                    <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                        <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                            <CardContent>
                                {isLoading ? (
                                    <Skeleton
                                        variant='rectangular'
                                        width={210}
                                        height={120}
                                        style={{ width: '100%' }}
                                    />
                                ) : (
                                    <div>
                                        <Typography component='h2' variant='subtitle2' gutterBottom>
                                            Total spent in mint
                                        </Typography>
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{
                                                    alignContent: { xs: 'center', sm: 'flex-start' },
                                                    alignItems: 'flex-end',
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography variant='h4' component='p'>
                                                    {runeData.totalSpentInMintsSats
                                                        ? millify(runeData.totalSpentInMintsSats)
                                                        : 0}{' '}
                                                    sats
                                                </Typography>
                                                <Typography gutterBottom component='p' sx={{ color: 'text.secondary' }}>
                                                    $
                                                    {runeData.totalSpentInMintsUsd
                                                        ? millify(runeData.totalSpentInMintsUsd)
                                                        : 0}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Total USD spent in minting {runeData.mints} bags. This can serve an
                                                initial realized value of the token.
                                            </Typography>
                                        </Stack>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ sm: 12, md: 6 }} style={{ width: '100%' }}>
                    <RunePriceSeries runeName={props.address} />
                </Grid>
                {adjustedPremine < supply && (
                    <Grid size={{ sm: 12, md: 6 }}>
                        {/* Add etched and next minted runes */}
                        {/* Mint progression per percentage of supply or value invested */}
                        {/* Value invested per mint */}
                        {/* Add separate pages for runes with mints in progress, this mints details are not relevant in large runes */}
                        {isLoading ? (
                            <Skeleton variant='rectangular' width={210} height={300} style={{ width: '100%' }} />
                        ) : (
                            <SessionsChart
                                title='Mints'
                                subtitle='Mint progression'
                                series={[
                                    {
                                        id: 'mints',
                                        label: 'Mints',
                                        showMark: false,
                                        curve: 'linear',
                                        stack: 'total',
                                        area: true,
                                        stackOrder: 'ascending',
                                        data: runeData.mintHistory.map((mint: any) => mint.cumulativeMints),
                                    },
                                ]}
                                xAxis={[
                                    {
                                        scaleType: 'point',
                                        data: runeData.mintHistory.map((mint: any) => mint.block),
                                        tickInterval: (index: any, i: any) => (i + 1) % 5 === 0,
                                    },
                                ]}
                                titleNumber={runeData.mints}
                                chipColor='primary'
                                chipLabel={
                                    runeData?.mintHistory && runeData.mintHistory.length > 2
                                        ? '+' +
                                          (runeData.mintHistory[runeData.mintHistory.length - 1]?.cumulativeMints -
                                              runeData.mintHistory[0]?.cumulativeMints)
                                        : ''
                                }
                                color='primary'
                                isTimeFilterEnabled={true}
                                defaultTimeFilter='all'
                                style={{ width: '100%' }}
                            />
                        )}
                    </Grid>
                )}
                {/* Change order if mints is shown or not */}
                <Grid size={{ xs: 12, lg: 6 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <LastActivities runeData={runeData} />
                        </Stack>
                    )}
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <OrdersBook runeData={runeData} />
                        </Stack>
                    )}
                </Grid>
                <Grid size={{ xs: 12, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <RuneHolders rune={runeData} holders={runeData.holders} />
                        </Stack>
                    )}
                </Grid>
                {/* Add holders analys. How distributed it is. */}
                {/* Add notable holders */}
                {/* Does it worth to mint now? Get an estimate per bag, price spent in mint */}
                {/* Get links to price */}
                {/* Sentiment analysis on twitter */}
                {/* Get official socials */}
                {/* Add advanced tab on the bottom with deploy tx */}
                {/* Create a separate tab on the left that makes the right panel change with no reloadings*/}
                {/* Add last orders no magic eden */}
                {/* Add last tweets */}
                {/* Icrease price and market cap size?*/}
                {/* Add simple identification information about the rune, like id and number */}
            </Grid>
            <Copyright sx={{ my: 4 }} />
        </Box>
    );
}
