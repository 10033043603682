import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { isTaprootAddress, formatPercentage } from './Utils';

import { Link } from 'react-router-dom';

const colorsArr = ['hsl(220, 25%, 65%)', 'hsl(220, 25%, 45%)', 'hsl(220, 25%, 30%)', 'hsl(220, 25%, 20%)'];

const formatAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

interface StyledTextProps {
    variant: 'primary' | 'secondary';
}

const StyledText = styled('text', {
    shouldForwardProp: (prop) => prop !== 'variant',
})<StyledTextProps>(({ theme }: { theme: any }) => ({
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fill: (theme.vars || theme).palette.text.secondary,
    variants: [
        {
            props: {
                variant: 'primary',
            },
            style: {
                fontSize: theme.typography.h5.fontSize,
            },
        },
        {
            props: ({ variant }) => variant !== 'primary',
            style: {
                fontSize: theme.typography.body2.fontSize,
            },
        },
        {
            props: {
                variant: 'primary',
            },
            style: {
                fontWeight: theme.typography.h5.fontWeight,
            },
        },
        {
            props: ({ variant }) => variant !== 'primary',
            style: {
                fontWeight: theme.typography.body2.fontWeight,
            },
        },
    ],
}));

interface PieCenterLabelProps {
    primaryText: string;
    secondaryText: string;
}

function PieCenterLabel({ primaryText, secondaryText }: PieCenterLabelProps) {
    const { width, height, left, top } = useDrawingArea();
    const primaryY = top + height / 2 - 10;
    const secondaryY = primaryY + 24;

    return (
        <React.Fragment>
            <StyledText variant='primary' x={left + width / 2} y={primaryY}>
                {primaryText}
            </StyledText>
            <StyledText variant='secondary' x={left + width / 2} y={secondaryY}>
                {secondaryText}
            </StyledText>
        </React.Fragment>
    );
}
const colors = ['hsl(220, 20%, 65%)', 'hsl(220, 20%, 42%)', 'hsl(220, 20%, 35%)', 'hsl(220, 20%, 25%)'];

export default function RuneHolders(props: any) {
    // TODO: identify key wallets
    // TODO: enable to click on wallets and see what they are holding
    // TODO: enable advanced statistical metrics
    // TODO: enable strategu for large numbers, dog is an example of overflow

    if (!props.holders) {
        return (
            <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
                <CardContent>
                    <Typography component='h2' variant='subtitle2'>
                        Distribution of holders
                    </Typography>
                    No data to display
                </CardContent>
            </Card>
        );
    }

    const supply =
        BigInt(props.rune.termsAmount || 0) * BigInt(props.rune.termsCap || 0) + BigInt(props.rune.premine || 0);
    const topHoldersSupply =
        props.holders?.detail.reduce((acc: bigint, holder: any) => acc + BigInt(holder.amount), BigInt(0)) || BigInt(0);
    const topHolders =
        props.holders?.detail.slice(0, 25).map((holder: any) => {
            return {
                label: holder.address,
                value: BigInt(holder.amount),
            };
        }) || [];
    topHolders.push({
        label: 'Others',
        value: supply - topHoldersSupply,
    });
    topHolders.sort((a: any, b: any) => Number(b.value - a.value));
    return (
        <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2'>
                    Distribution of holders
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PieChart
                        colors={colors}
                        margin={{
                            left: 80,
                            right: 80,
                            top: 80,
                            bottom: 80,
                        }}
                        series={[
                            {
                                data: topHolders.map((holder: any) => ({
                                    label: holder.label,
                                    value: Number(holder.value),
                                })),
                                innerRadius: 75,
                                outerRadius: 100,
                                paddingAngle: 0,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                            },
                        ]}
                        height={260}
                        width={260}
                        slotProps={{
                            legend: { hidden: true },
                        }}
                    >
                        <PieCenterLabel primaryText={props.holders?.total} secondaryText='Total' />
                    </PieChart>
                </Box>
                {topHolders.slice(0, 5).map((holder: any, index: any) => (
                    <Stack key={index} direction='row' sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
                        {/* {country.flag} */}
                        <Stack sx={{ gap: 1, flexGrow: 1 }}>
                            <Stack
                                direction='row'
                                sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <Tooltip title={holder.label} placement='top'>
                                    <Typography variant='body2' sx={{ fontWeight: '500' }}>
                                        {holder.label != 'Others' ? (
                                            <Link
                                                to={`/${holder.label}`}
                                                rel='noopener noreferrer'
                                                style={{ textDecoration: 'none' }}
                                                color='primary'
                                            >
                                                {formatAddress(holder.label)}
                                            </Link>
                                        ) : (
                                            holder.label
                                        )}
                                    </Typography>
                                </Tooltip>
                                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                    {formatPercentage(holder.value, supply.toString())}
                                </Typography>
                            </Stack>
                            <LinearProgress
                                variant='determinate'
                                aria-label='Number of users by country'
                                value={(100 * Number(holder.value)) / Number(supply)}
                                sx={{
                                    [`& .${linearProgressClasses.bar}`]: {
                                        backgroundColor: colorsArr[index],
                                    },
                                }}
                            />
                        </Stack>
                    </Stack>
                ))}
            </CardContent>
        </Card>
    );
}
