import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { areaElementClasses } from '@mui/x-charts/LineChart';

export type StatCardProps = {
    title: string;
    value: string;
    interval: string;
    trend: string;
    data: number[];
    xArray: string[];
    valueFormatter?: (value: any) => string;
    xAxisFormatter?: (value: any) => string;
};

function AreaGradient({ color, id }: { color: string; id: string }) {
    return (
        <defs>
            <linearGradient id={id} x1='50%' y1='0%' x2='50%' y2='100%'>
                <stop offset='0%' stopColor={color} stopOpacity={0.3} />
                <stop offset='100%' stopColor={color} stopOpacity={0} />
            </linearGradient>
        </defs>
    );
}

export default function StatCard({
    title,
    value,
    interval,
    trend,
    data,
    xArray,
    valueFormatter,
    xAxisFormatter,
}: StatCardProps) {
    const theme: any = useTheme();

    const trendColors = new Map<string, string>([
        ['up', theme.palette.mode === 'light' ? theme.palette.success.main : theme.palette.success.dark],
        ['down', theme.palette.mode === 'light' ? theme.palette.error.main : theme.palette.error.dark],
        ['neutral', theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[700]],
    ]);

    const labelColors = new Map<string, 'success' | 'error' | 'default'>([
        ['up', 'success'],
        ['down', 'error'],
        ['neutral', 'default'],
    ]);

    const chartColor = trendColors.get(trend || 'neutral') || theme.palette.grey[400];

    return (
        <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2' gutterBottom>
                    {title}
                </Typography>
                <Stack direction='column' sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}>
                    <Stack sx={{ justifyContent: 'space-between' }}>
                        <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h4' component='p'>
                                {value}
                            </Typography>
                            {/* {trend && trend !== 'neutral' && (
                                <Chip size='small' color={labelColors[trend]} label={trendValues[trend]} />
                            )} */}
                        </Stack>
                        <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                            {interval}
                        </Typography>
                    </Stack>
                    {data && data.length > 0 && (
                        <Box sx={{ width: '100%', height: 50 }}>
                            <SparkLineChart
                                colors={[chartColor]}
                                data={data}
                                area
                                showHighlight
                                showTooltip
                                xAxis={{
                                    scaleType: 'band',
                                    data: xArray, // Use the correct property 'data' for xAxis
                                    valueFormatter: xAxisFormatter || ((value: any) => `${value}`),
                                }}
                                sx={{
                                    [`& .${areaElementClasses.root}`]: {
                                        fill: `url(#area-gradient-${value})`,
                                    },
                                }}
                                valueFormatter={valueFormatter || ((value: any) => `${value}`)}
                            >
                                <AreaGradient color={chartColor} id={`area-gradient-${value}`} />
                            </SparkLineChart>
                        </Box>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
