import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SelectContent from './SelectContent';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';

import RunesTimeline from './RunesTimeline';
import HottestRunesMinted from './HottestRunesMinted';
import RuneWalletInput from './RuneWalletInput';
import TopRunes from './TopRunes';

import { FavoriteWallets } from './Favorite';

import { Link } from 'react-router-dom';

import logo from '../logol.png';

const drawerWidth = 450;

const Drawer = styled(MuiDrawer)({
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    mt: 10,
    [`& .${drawerClasses.paper}`]: {
        width: drawerWidth,
        boxSizing: 'border-box',
    },
});

// TODO: customizable side menu

export default function SideMenu() {
    return (
        <Drawer
            variant='permanent'
            sx={{
                display: { xs: 'none', md: 'block' },
                [`& .${drawerClasses.paper}`]: {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', padding: '20px 0 0 45px' }}>
                <Link
                    to={{
                        pathname: '/',
                    }}
                >
                    <img src={logo} alt='Logo' width={306} height={63} />
                </Link>
            </div>
            <RuneWalletInput />
            <FavoriteWallets />
            <Divider />
            {/* <MenuContent /> */}
            <Divider />
            <TopRunes title='Top runes by volume' field='volume' limit={10} />
            <Divider />
            <HottestRunesMinted />
            <Divider />
            <RunesTimeline />
            <Divider />
            {/* <CardAlert /> */}
        </Drawer>
    );
}
