import React from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';

import backendApi from '../apis/backend';

import { Skeleton } from '@mui/material';

import SessionsChart from './SessionsChart';

import { formatSmallNumber, sanitizeRune } from './Utils';

export function RunePriceSeries(props: any) {
    const [limit, setLimit] = React.useState('1d');

    const {
        isLoading,
        isError,
        data: runeData,
    } = useQuery({
        queryKey: ['rune_price_series', props.runeName, limit],
        queryFn: async () => {
            const response = await backendApi.get('rune/get_rune_price_series', {
                params: {
                    spaced_rune: sanitizeRune(props.runeName),
                    limit: limit,
                },
            });
            return response.data;
        },
    });

    if (isError) {
        return <div>Error</div>;
    }

    let currentPrice = 0;
    let priceChange = 0;
    if (!isLoading) {
        currentPrice =
            runeData.mePriceSeries?.[runeData.mePriceSeries?.length - 1].cFP * 10 ** runeData.divisibility || 0;
        priceChange =
            runeData.mePriceSeries?.[runeData.mePriceSeries?.length - 1].cFP / runeData.mePriceSeries?.[0].cFP;
    }

    return isLoading ? (
        <Skeleton variant='rectangular' width={210} height={300} style={{ width: '100%' }} />
    ) : (
        <SessionsChart
            title='Price'
            subtitle={
                formatSmallNumber((currentPrice * (runeData?.prices?.btc || 0)) / 10 ** 8 || 0) +
                ` USD / ${runeData?.symbol || 'Unit'}`
            }
            series={[
                {
                    id: 'price',
                    label: 'Price',
                    showMark: false,
                    curve: 'linear',
                    stack: 'total',
                    area: true,
                    stackOrder: 'ascending',
                    data: runeData?.mePriceSeries?.map((price: any) => price.cFP * 10 ** runeData.divisibility) || [],
                },
            ]}
            xAxis={[
                {
                    scaleType: 'point',
                    data:
                        // TODO: adjust to local time
                        runeData?.mePriceSeries?.map((price: any) => moment.utc(price.ts).format('MMM DD, HH:mm')) ||
                        [],
                    tickInterval: (index: any, i: any) => (i + 1) % 5 === 0,
                },
            ]}
            titleNumber={formatSmallNumber(currentPrice) + ` sats / ${runeData?.symbol || 'Unit'}`}
            chipColor={priceChange > 1 ? 'success' : 'error'}
            chipLabel={
                priceChange
                    ? priceChange > 1
                        ? '+' + (100 * (priceChange - 1)).toFixed(2) + '%'
                        : '-' + (100 * (1 - priceChange)).toFixed(2) + '%'
                    : ''
            }
            color='primary'
            onFilterTimeChange={(value: string) => {
                console.log(value);
                setLimit(value);
            }}
            selectedTimeFilter={limit}
        />
    );
}
