import * as React from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-charts/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MainGrid from './components/MainGrid';
import RuneMainGrid from './components/RuneMainGrid';
import HomeMainGrid from './components/HomeMainGrid';
import AppTheme from './theme/AppTheme';
import SideMenu from './components/SideMenu';
import AppNavbar from './components/AppNavbar';
import Alert from '@mui/material/Alert';

import {
    chartsCustomizations,
    dataGridCustomizations,
    datePickersCustomizations,
    treeViewCustomizations,
} from './theme/customizations';
import WalletMainGrid from './components/WalletMainGrid';

const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
};

const isTaprootAddress = (address: string | null | undefined) =>
    address != null && address.toLowerCase().startsWith('bc1');

const isRune = (address: string | null | undefined) =>
    address != null && !!address.match(/^[A-Za-z•.]+/) && !address.toLowerCase().startsWith('bc1');

export default function Dashboard(props: { address: string | null | undefined }) {
    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
            <CssBaseline enableColorScheme />
            <Box sx={{ display: 'flex' }}>
                <SideMenu />
                {/* Main content */}
                <Box
                    component='main'
                    sx={(theme: any) => ({
                        flexGrow: 1,
                        backgroundColor: theme.vars
                            ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                            : alpha(theme.palette.background.default, 1),
                        overflow: 'auto',
                    })}
                >
                    <Stack
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                            mx: 3,
                            pb: 10,
                            mt: { xs: 8, md: 0 },
                        }}
                    >
                        <AppNavbar />
                        {(() => {
                            if (isRune(props.address)) {
                                return <RuneMainGrid address={props.address} />;
                            } else if (isTaprootAddress(props.address)) {
                                return <WalletMainGrid address={props.address} />;
                            } else {
                                return <RuneMainGrid address={'DOG•GO•TO•THE•MOON'} />;
                            }
                        })()}
                    </Stack>
                </Box>
            </Box>
        </AppTheme>
    );
}
