import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import millify from 'millify';
import {
    Avatar,
    Badge,
    Box,
    Card,
    CardContent,
    CardMedia,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { formatSmallNumber } from './Utils';

import FavoriteIcon from '@mui/icons-material/Favorite';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
    },
}));

export const AddWalletToFavorite = (address: string) => {
    if (localStorage.getItem('favorite_wallets') == null) {
        localStorage.setItem('favorite_wallets', '[]');
    }

    if (IsWalletInFavorite(address)) {
        return;
    }

    let favoriteWallets = JSON.parse(localStorage.getItem('favorite_wallets') || '[]');
    favoriteWallets.push({
        address: address,
        label: 'Rune Wallet',
        assetsValue: 2176892,
        btcValue: 0.1,
    });
    localStorage.setItem('favorite_wallets', JSON.stringify(favoriteWallets));
};

export const RemoveWalletFromFavorite = (address: string) => {
    if (localStorage.getItem('favorite_wallets') == null || localStorage.getItem('favorite_wallets') === '[]') {
        return;
    }

    let favoriteWallets = JSON.parse(localStorage.getItem('favorite_wallets') || '[]');
    favoriteWallets = favoriteWallets.filter((wallet: any) => wallet.address !== address);
    localStorage.setItem('favorite_wallets', JSON.stringify(favoriteWallets));
};

export const IsWalletInFavorite = (address: string) => {
    let favoriteWallets = JSON.parse(localStorage.getItem('favorite_wallets') || '[]');
    return favoriteWallets.some((wallet: any) => wallet.address === address);
};

export const FavoriteWallets = () => {
    if (localStorage.getItem('favorite_wallets') == null || localStorage.getItem('favorite_wallets') === '[]') {
        return <></>;
    }
    const favoriteWallets = JSON.parse(localStorage.getItem('favorite_wallets') || '[]');
    return (
        <Box
            sx={{
                width: { xs: '100%', md: '100%' },
            }}
        >
            <Divider />
            {/* TODO: extend this to runes most minted in the last X blocks */}
            {/* TODO: get runes image */}
            <Typography
                component='h2'
                variant='subtitle2'
                gutterBottom
                sx={{ fontWeight: '600', padding: '6px 10px', margin: 0 }}
            >
                Favorite Wallets
            </Typography>
            <List sx={{ width: '100%' }}>
                {favoriteWallets.map((wallet: any, index: number) => {
                    // TODO: Add tooltip to the rune item with the rune details?
                    // TODO: maybe add detailed page for both metrics in the home?
                    // TODO: add market cap
                    // TODO: add see more
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                alignItems='center'
                                component={Link}
                                to={`/${wallet.address}`}
                                style={{ color: 'inherit' }}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <FavoriteIcon color={'secondary'} />
                                    </Avatar>
                                </ListItemAvatar>
                                {/* <HtmlTooltip
                                    placement='right'
                                    title={
                                        <Card sx={{ maxWidth: 345 }}>
                                            <CardContent>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                    <strong>TITLE</strong>
                                                </Typography>
                                                <ul>
                                                    <li>
                                                        Rune was etched <strong>SOME TIME</strong> ago
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    }
                                > */}
                                <ListItemText
                                    primary={
                                        <div>
                                            <strong>
                                                {wallet.address.slice(0, 6)}...
                                                {wallet.address.slice(-6)}
                                            </strong>
                                            {/* <br />
                                                <Typography variant='caption'>
                                                    <strong>${millify(wallet.assetsValue)}</strong> in assets
                                                </Typography> */}
                                            {/* <br />
                                            <Typography variant='caption'>
                                                {'  '} {formattedTimePassed} ago
                                            </Typography> */}
                                        </div>
                                    }
                                />
                                {/* </HtmlTooltip> */}
                            </ListItem>
                            {index < favoriteWallets.length - 1 && <Divider variant='inset' component='li' />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Box>
    );
};
