import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Divider from '@mui/material/Divider';

import RuneWalletInput from './RuneWalletInput';
import { FavoriteWallets } from './Favorite';
import TopRunes from './TopRunes';
import HottestRunesMinted from './HottestRunesMinted';
import RunesTimeline from './RunesTimeline';
import CardAlert from './CardAlert';

const mainListItems = [
    { text: 'Home', icon: <HomeRoundedIcon /> },
    { text: 'Analytics', icon: <AnalyticsRoundedIcon /> },
    { text: 'Clients', icon: <PeopleRoundedIcon /> },
    { text: 'Tasks', icon: <AssignmentRoundedIcon /> },
];

const secondaryListItems = [
    { text: 'Settings', icon: <SettingsRoundedIcon /> },
    { text: 'About', icon: <InfoRoundedIcon /> },
    { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

export default function MenuContent() {
    return (
        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
            <RuneWalletInput />
            <FavoriteWallets />
            <Divider />
            {/* <MenuContent /> */}
            <Divider />
            <TopRunes title='Top runes by volume' field='volume' limit={10} />
            <Divider />
            <HottestRunesMinted />
            <Divider />
            <RunesTimeline />
            <Divider />
            {/* <CardAlert /> */}
        </Stack>
    );
}
