import bigDecimal from 'js-big-decimal';

export const isTaprootAddress = (address: string | null | undefined) => address != null && address.startsWith('bc1');

export const isRune = (address: string | null | undefined) =>
    address != null && address.match(/^[A-Za-z•.]+/) && address.length <= 26;

export const isRuneOrAddress = (address: string | null | undefined) => isTaprootAddress(address) || isRune(address);

export const sanitizeRune = (rune: string) => rune.toUpperCase().replace(/[ .]/g, '•');

export const formatSmallNumber = (num: number) => {
    if (num < 10 * -9) {
        return num.toPrecision(3);
    }
    return parseFloat(num.toPrecision(3)).toString();
};

export const formatPercentage = (numerator: number | string, denominator: number | string) => {
    if (denominator === 0) {
        return '0%';
    }
    return `${new bigDecimal(numerator)
        .divide(new bigDecimal(denominator))
        .multiply(new bigDecimal('100'))
        .round(2)
        .getPrettyValue()}%`;
};
