import * as React from 'react';

import moment from 'moment';

import { useEffect, useState } from 'react';

import { useQueries } from 'react-query';

import backendApi from '../apis/backend';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import RuneHolders from './RuneHolders';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

import OrdersBook from './OrdersBook';
import LastActivities from './LastActivities';

import millify from 'millify';

import { Link } from 'react-router-dom';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import WalletRunesDataGrid from './WalletRunesDataGrid';

import { AddWalletToFavorite, RemoveWalletFromFavorite, IsWalletInFavorite } from './Favorite';

import { formatSmallNumber, sanitizeRune } from './Utils';
import WalletActivities from './WalletActivities';

export default function WalletMainGrid(props: any) {
    const [{ data: walletData, isLoading, isError: isErrorWallet }] = useQueries([
        {
            queryKey: ['wallet', props.address],
            queryFn: async () => {
                const response = await backendApi.get('wallet/get_wallet', {
                    params: {
                        address: props.address,
                    },
                });
                return response.data;
            },
        },
    ]);

    if (isErrorWallet || walletData === '') {
        return <div>Wallet was not found</div>;
    }

    let priceChange =
        walletData?.portifolio &&
        walletData?.portifolio.series.slice(-1)[0].valueUsd / walletData?.portifolio.series[0].valueUsd;

    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            {/* cards */}
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Alert severity='info' style={{ width: '100%' }}>
                    This application is in active development. Improvements and new features are being added. Bugs are
                    being fixed as they are discovered. Please report any issues you find.
                </Alert>
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <HighlightedCard
                        title='Enjoying the app? Refer us to your crypto goups!'
                        subtitle='Help us grow and improve our products'
                    />
                </Grid> */}
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <React.Fragment>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography variant='h5' component='div'>
                                        {props.address.slice(0, 6)}...{props.address.slice(-6)}
                                    </Typography>
                                    {/* <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Etched {formattedTimePassed} ago
                                    </Typography> */}
                                    <br></br>
                                    <IconButton
                                        color='secondary'
                                        target='_blank'
                                        onClick={() => {
                                            if (!IsWalletInFavorite(props.address)) {
                                                AddWalletToFavorite(props.address);
                                            } else {
                                                RemoveWalletFromFavorite(props.address);
                                            }
                                            // Quick and dirty way to refresh the page
                                            window.location.reload();
                                        }}
                                        href=''
                                    >
                                        {IsWalletInFavorite(props.address) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                    </IconButton>
                                </div>
                            )}
                        </CardContent>
                    </React.Fragment>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Bitcoin balance
                                    </Typography>
                                    <Stack
                                        direction='column'
                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                    >
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Typography variant='h6' component='p'>
                                                    {walletData?.wallet.sat_balance / 10 ** 8} BTC
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                $
                                                {formatSmallNumber(
                                                    (walletData.prices.btc * walletData.wallet.sat_balance) / 10 ** 8
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                    ) : (
                        <StatCard
                            title='Assets value'
                            value={'$' + millify(runeData.meMarketInfo.marketCap * 1000 * 100)}
                            interval='All time'
                            data={
                                runeData?.mintHistory
                                    ?.map((mint: any) => mint?.cumulativeMints)
                                    ?.slice(-blocksPerDay) || []
                            }
                            xArray={runeData?.mintHistory?.map((mint: any) => mint.block)?.slice(-blocksPerDay) || []}
                        />
                    )}
                </Grid> */}
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Address actions
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        <Button
                                            variant='outlined'
                                            color='secondary'
                                            href={`https://magiceden.io/runes/${sanitizeRune(props.address)}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            endIcon={<XIcon />}
                                        >
                                            Favorite
                                        </Button>
                                    </Box>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ sm: 12, md: 6 }} style={{ width: '100%' }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={300} style={{ width: '100%' }} />
                    ) : (
                        <SessionsChart
                            title='Assets value (24h)'
                            subtitle={formatSmallNumber(walletData.portifolio.totalAssetsValueSats / 10 ** 8) + ' BTC'}
                            series={[
                                {
                                    id: 'price',
                                    label: 'Price',
                                    showMark: false,
                                    curve: 'linear',
                                    stack: 'total',
                                    area: true,
                                    stackOrder: 'ascending',
                                    data:
                                        walletData?.portifolio.series.map(
                                            (portifolioRecord: any) => portifolioRecord.valueUsd
                                        ) || [],
                                },
                            ]}
                            xAxis={[
                                {
                                    scaleType: 'point',
                                    data:
                                        // TODO: adjust to local time
                                        walletData?.portifolio.series.map((portifolioRecord: any) =>
                                            moment.utc(portifolioRecord.timestamp).format('MMM DD, HH:mm')
                                        ) || [],
                                    tickInterval: (index: any, i: any) => (i + 1) % 5 === 0,
                                },
                            ]}
                            titleNumber={'$' + formatSmallNumber(walletData.portifolio.totalAssetsValueUsd)}
                            chipColor={priceChange > 1 ? 'success' : 'error'}
                            chipLabel={
                                priceChange
                                    ? priceChange > 1
                                        ? '+' + (100 * (priceChange - 1)).toFixed(2) + '%'
                                        : '-' + (100 * (1 - priceChange)).toFixed(2) + '%'
                                    : ''
                            }
                            color='secondary'
                            isTimeFilterEnabled={false}
                        />
                    )}
                </Grid>
                {/* Change order if mints is shown or not */}
                <Grid size={{ xs: 12, lg: 6 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <WalletRunesDataGrid walletData={walletData} />
                        </Stack>
                    )}
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <WalletActivities walletData={walletData} address={props.address} />
                        </Stack>
                    )}
                </Grid>
                {/* <Grid size={{ xs: 12, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            <RuneHolders rune={runeData} holders={runeData.holders} />
                        </Stack>
                    )}
                </Grid> */}
                {/* Add holders analys. How distributed it is. */}
                {/* Add notable holders */}
                {/* Does it worth to mint now? Get an estimate per bag, price spent in mint */}
                {/* Get links to price */}
                {/* Sentiment analysis on twitter */}
                {/* Get official socials */}
                {/* Add advanced tab on the bottom with deploy tx */}
                {/* Create a separate tab on the left that makes the right panel change with no reloadings*/}
                {/* Add last orders no magic eden */}
                {/* Add last tweets */}
                {/* Icrease price and market cap size?*/}
                {/* Add simple identification information about the rune, like id and number */}
            </Grid>
            <Copyright sx={{ my: 4 }} />
        </Box>
    );
}
