import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function AreaGradient({ color, id }: { color: string; id: string }) {
    return (
        <defs>
            <linearGradient id={id} x1='50%' y1='0%' x2='50%' y2='100%'>
                <stop offset='0%' stopColor={color} stopOpacity={0.5} />
                <stop offset='100%' stopColor={color} stopOpacity={0} />
            </linearGradient>
        </defs>
    );
}

// TODO: fix chip to be shown as percentage of the current series or absolute value
// TODO: disable time range selection
export default function SessionsChart(props: any) {
    const theme: any = useTheme();

    let series = props.series;
    let xAxis = props.xAxis;

    const colorPalette = [
        theme.palette[props.color].light,
        theme.palette[props.color].main,
        theme.palette[props.color].dark,
    ];

    return (
        <Card variant='outlined' sx={{ width: '100%' }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2' gutterBottom>
                    {props.title}
                </Typography>
                <Stack sx={{ justifyContent: 'space-between' }}>
                    <Stack
                        direction='row'
                        sx={{
                            alignContent: { xs: 'center', sm: 'flex-start' },
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography variant='h4' component='p'>
                            {props.titleNumber}
                        </Typography>
                        <Chip size='small' color={props.chipColor} label={props.chipLabel} />
                    </Stack>
                    <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                        {props.subtitle}
                    </Typography>
                </Stack>
                <LineChart
                    colors={colorPalette}
                    xAxis={xAxis}
                    series={series}
                    height={250}
                    margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                    grid={{ horizontal: true }}
                    sx={{
                        '& .MuiAreaElement-series-price': {
                            fill: "url('#price')",
                        },
                        '& .MuiAreaElement-series-fees': {
                            fill: "url('#fees')",
                        },
                        '& .MuiAreaElement-series-mints': {
                            fill: "url('#mints')",
                        },
                    }}
                    slotProps={{
                        legend: {
                            hidden: true,
                        },
                    }}
                >
                    <AreaGradient color={theme.palette[props.color].dark} id='price' />
                    <AreaGradient color={theme.palette[props.color].main} id='fees' />
                    <AreaGradient color={theme.palette[props.color].light} id='mints' />
                </LineChart>
                {props.onFilterTimeChange && (
                    <>
                        <br />
                        <ToggleButtonGroup
                            aria-label='Basic button group'
                            sx={{ justifyContent: 'flex-end', display: 'flex' }}
                            style={{ padding: '0 10px' }}
                            onChange={(event, newSelected) => {
                                props.onFilterTimeChange(newSelected?.[0]);
                            }}
                        >
                            <ToggleButton value='1d' size='small' selected={props.selectedTimeFilter === '1d'}>
                                1 day
                            </ToggleButton>
                            <ToggleButton value='7d' size='small' selected={props.selectedTimeFilter === '7d'}>
                                7 days
                            </ToggleButton>
                            <ToggleButton value='30d' size='small' selected={props.selectedTimeFilter === '30d'}>
                                1 month
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </>
                )}
            </CardContent>
        </Card>
    );
}
